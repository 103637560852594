import { put, call } from "redux-saga/effects";
import * as types from "../../../Actions/RxHistory/RxHistoryConstants";
import * as api from "../../../../util/api";
import { apiRoutes } from "../../../../routes"

export function* rxhistoryNotPickupPrescriptionSagas(value) {
    if (value?.payload) {
    let response = "";
    const {payload} = value;
    try {
        response = yield call(api.get, `${apiRoutes.rxhistoryNotPickupPrescription}/${payload}`);
        yield put({ type: types.RXHISTORY_NOTPICKUP_PRESCRIPTION_SUCCESS, response });
    } catch (error) {
        let errorResponse = error?.response?.data;
        yield put({
            type: types.RXHISTORY_NOTPICKUP_PRESCRIPTION_ERROR,
            response: errorResponse,
        });
        return false;
    }
}
}
export function* rxhistoryPickedupPrescriptionSagas(value) {
    if (value?.payload) {
    let response = "";
    const {payload} = value;
    try {
        response = yield call(api.get, `${apiRoutes.rxhistoryPickedupPrescription}/${payload}`);
        yield put({ type: types.RXHISTORY_PICKEDUP_PRESCRIPTION_SUCCESS, response });
    } catch (error) {
        let errorResponse = error?.response?.data;
        yield put({
            type: types.RXHISTORY_PICKEDUP_PRESCRIPTION_ERROR,
            response: errorResponse,
        });
        return false;
    }
}
}
export function* rxhistoryClearedPrescriptionSagas(value) {
    if (value?.payload) {
    let response = "";
    const {payload} = value;
    try {
        response = yield call(api.get, `${apiRoutes.rxhistoryClearedPrescription}/${payload}`);
        yield put({ type: types.RXHISTORY_CLEARED_PRESCRIPTION_SUCCESS, response });
    } catch (error) {
        let errorResponse = error?.response?.data;
        yield put({
            type: types.RXHISTORY_CLEARED_PRESCRIPTION_ERROR,
            response: errorResponse,
        });
        return false;
    }
}
}

export function* pickupRxAwaitingRecordSagas(value) {
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
            response = yield call(api.deleteRec, `${apiRoutes.pickupRx}/${payload}`);
            yield put({ type: types.PICKUP_RX_AWAITING_RECORD_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.PICKUP_RX_AWAITING_RECORD_ERROR,
                response: errorResponse,
            });
            return false;
        }

    }
}

export function* clearRxAwaitingRecordSagas(value) {
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
            response = yield call(api.deleteRec, `${apiRoutes.clearRx}/${payload}`);
            yield put({ type: types.CLEAR_RX_AWAITING_RECORD_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.CLEAR_RX_AWAITING_RECORD_ERROR,
                response: errorResponse,
            });
            return false;
        }

    }
}
