
import { createStore,applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer from "../RootReducer";
import startWatching from "../Middleware/Saga";

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
  
    return {
      ...createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware))),
      runSaga: sagaMiddleware.run(startWatching)
    }
  };
  
  export default configureStore;