import * as types from '../../Actions/SearchCriteria/SearchCriteriaConstants';

export function searchCriteriaReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.ZIPCODE:
            return { loading: true, action };
        case types.ZIPCODE_SUCCESS:
            return { loading: true, response };
        case types.ZIPCODE_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function pharmacyListReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.PHARMACY_LIST:
            return { loading: true, action };
        case types.PHARMACY_LIST_SUCCESS:
            return { loading: true, response };
        case types.PHARMACY_LIST_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function individualPharmacyListWithDrugPriceReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE:
            return { loading: true, action };
        case types.INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE_SUCCESS:
            return { loading: true, response };
        case types.INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};
