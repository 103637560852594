import Main from "components/Layout/Main"
import ChangePassword from "components/ChangePassword"
function ChangePasswordContainer(){

    return(
        <Main>
            <ChangePassword/>
        </Main>
    )
}

export default ChangePasswordContainer