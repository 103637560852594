export const LOGIN_AUTH = 'LOGIN_AUTH';
export const LOGIN_AUTH_SUCCESS ='LOGIN_AUTH_SUCCESS';
export const LOGIN_AUTH_ERROR = 'LOGIN_AUTH_ERROR';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS ='LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const SIGNUP_AUTH = 'SIGNUP_AUTH';
export const SIGNUP_AUTH_SUCCESS = 'SIGNUP_AUTH_SUCCESS';
export const SIGNUP_AUTH_ERROR = 'SIGNUP_AUTH_ERROR';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const UPDATE = 'UPDATE';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_ERROR = 'UPDATE_ERROR';

export const UPDATE_PATIENT_PROFILE = 'UPDATE_PATIENT_PROFILE';
export const UPDATE_PATIENT_PROFILE_SUCCESS = 'UPDATE_PATIENT_PROFILE_SUCCESS';
export const UPDATE_PATIENT_PROFILE_ERROR = 'UPDATE_PATIENT_PROFILE_ERROR';