const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const routes = {
     logIn: {endpoint: `${BASE_URL}/check-login`, path: '/', pageTitle: 'LogIn', menuTitle: 'LogIn'},
     signUp: {endpoint: `${BASE_URL}/signup`, path: '/signup', pageTitle: 'SignUp', menuTitle: 'SignUp'},
     updateProfile: {endpoint: `${BASE_URL}/updateprofile`, path: '/updateprofile', pageTitle: 'UpdateProfile', menuTitle: 'Update Profile'},
     myPrescriptions: {endpoint: `${BASE_URL}/myPrescriptions`, path: '/myprescriptions', pageTitle: 'MyPrescriptions', menuTitle: 'My Prescriptions'},
     rxHistory: {endpoint: `${BASE_URL}/rxHistory`, path: '/rxhistory', pageTitle: 'RxHistory', menuTitle: 'Rx History'},
     myProfile: {endpoint: `${BASE_URL}/myProfile`, path: '/myprofile', pageTitle: 'MyProfile', menuTitle: 'My Profile'},
     changePassword: {endpoint: `${BASE_URL}/changePassword`, path: '/changepassword', pageTitle: 'ChangePassword', menuTitle: 'Change Password'},
     notFound: { path: '*'},
     notAuthorized: { path: 'notauthorized'},
};

export default routes;

export const apiRoutes = {
     signupAuth: `/signup-auth`,
     signup: `/signup`,
     loginAuth: `/login-auth`,
     login: `/check-login`,
     patients: `/patients`,
     updateProfile: `/update-profile`,
     prescription: `/prescription`,
     patientPrescription: `/patient-prescription`,
     rxhistory: `/rxhistory`,
     clearRx: `/rxhistory/clear`,
     pickupRx: `/rxhistory/pickup`,
  
     rxhistoryNotPickupPrescription: `/rxhistory/notpickup-prescription`,
     rxhistoryPickedupPrescription: `/rxhistory/pickedup-prescription`,
     rxhistoryClearedPrescription: `/rxhistory/cleared-prescription`,
     zipCode: `/search-criteria`,
     pharmacyList: `/search-criteria/pharmacy-list`,
     individualPharmacyList: `/search-criteria/individual-pharmacy-list`,
     completePackagePrice: `/prescription/complete-package-price`,
     individualPackagePrice: `/prescription/individual-package-price`,
}