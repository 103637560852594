import Main from "components/Layout/Main";
import MyPrescription from "components/MyPrescription";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as constant from '../../util/constant';
import {patientPrescription} from "../../redux/Actions/Prescription";


function MyPrescriptionsContainer(){

const dispatch = useDispatch();
const [prescriptions, setPrescriptions] = useState([]);
const patientPrescriptionReducer = useSelector((state) => state.patientPrescriptionReducer);
let patient = JSON.parse(localStorage.getItem('loggedInUser'));

useEffect(()=>{
    if(patient?.id){
        dispatch(patientPrescription(patient?.id));
    }
    }, [dispatch,patient?.id]);
    
    useEffect(() => {
        if (patientPrescriptionReducer?.response) {
            if (patientPrescriptionReducer?.response[0].status === constant.READ_SUCCESS) {
                setPrescriptions(patientPrescriptionReducer?.response[0].data);
            }
        }
    }, [patientPrescriptionReducer]);
    return(
        <Main>
            <MyPrescription prescriptions = {prescriptions} />
        </Main>
    )
}

export default MyPrescriptionsContainer