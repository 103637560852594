import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import { createTheme } from '@mui/material';

const theme = createTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
