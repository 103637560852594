import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import OTPInput from 'components/common/OTPInput.js';
import './Signup.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as constant from '../../../util/constant';
import { usPhoneFormat } from 'util/phoneFormats';
import {  maskPhone } from 'util/phoneFormats.js';
import { successToast } from '../../../util/toast';
import * as api from "../../../util/api";
import { apiRoutes } from '../../../routes';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    borderRadius: '50px',
    //   border: '2px solid #000',
    //   boxShadow: 24,
    //   p: 4,
};

export default function Signup() {
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [otp, setOtp] = useState("");
    const [patientDetails, setPatientDetails] = useState({
        "patientId": location?.state?.patientId,
        "fName": "",
        "lName": "",
        "email": "",
        "phone": "",
        "password": "",
        "confirmPassword": ""
    });
    const [errors, setErrors] = useState({
        "password": "",
        "confirmPassword": "",
        "signup": ""
    });
    const [otpError, setOtpError] = useState("");
    const [isEmailExist, setIsEmailExist] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const [authResult, setAuthResult] = useState({});

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOtpError("");
        setOpen(false);
    }

    const handleChange = (e) => {
        if( e.target.name === "phone"){
            setPatientDetails({
                ...patientDetails,
                [e.target.name]: usPhoneFormat(e.target.value)
            })
        }else{
            setPatientDetails({
                ...patientDetails,
                [e.target.name]: e.target.value
            })
        }

        setErrors({
            "password": "",
            "confirmPassword": "",
            "signup": ""
        });
    }

    const signupAuth = (e) => {
        e.preventDefault();
        setIsEmailExist(false);


        if (validate()) { 
            api.post(`${apiRoutes.signupAuth}`, {
                "email": patientDetails.email,
                "fName": patientDetails.fName,
                "lName": patientDetails.lName,
                "phone": phoneFormatToNormalDigits(patientDetails.phone),
                "patientId": patientDetails.patientId

            }).then((result) => {
                if (result[0].status === constant.MESSAGE_SENT) {
                    setAuthResult(result[0]);
                    handleOpen();
                }
            }).catch(err => console.log(err))
        }
    }

    const signup = (e) => {
        e.preventDefault();
        if (otp === "") {
            setOtpError("Please enter code!");
            return;
        }
        /** Another approach to fix the redirect from login page  */

        // if (authResult.otp === otp) {

        api.post(`${apiRoutes.signup}`, {
            ...patientDetails,
            "otp": otp,
            "phone": phoneFormatToNormalDigits(patientDetails.phone)
        }).then((result) => {
            if (result[0].status === constant.VALID_SIGNUP) {
                if (result[0].data[0].status === constant.VALID_SIGNUP) {
                    successToast(constant.SIGNED_UP_MESSAGE);
                    handleClose();
                    navigate("/updateprofile", { state: { patientId: result[0].data[0].data } });
                }

            }

            if (result[0].status === constant.INVALID_SIGNUP) {
                if (result[0].data[0].status === constant.INVALID_SIGNUP)
                    setErrors({ ...errors, "signup": result[0].data[0].data });
                    handleClose();
            }
        }).catch(err => console.log(err))

        // } else {
        //     setOtpError("Please enter valid code!");
        // }
    }

    const phoneFormatToNormalDigits = (phone) => {
        return phone.substring(1, 4) + phone.substring(6, 9) + phone.substring(10);
    }

    const validate = () => {

        let isValid = true;
        let error = {};

        if (typeof patientDetails["password"] !== "undefined") {
            if (patientDetails["password"].length < 8) {
                isValid = false;
                error["password"] = "Please add at least 8 charachter.";
            }
        }
        if (typeof patientDetails["password"] !== "undefined" && typeof patientDetails["confirmPassword"] !== "undefined") {
            if (patientDetails["password"] !== patientDetails["confirmPassword"]) {
                isValid = false;
                error["confirmPassword"] = "Passwords don't match.";
            }
        }
        setErrors({ ...errors, ...error });

        return isValid;
    }

    const getOTP = (otp) => {
        setOtp(otp)
    }

    const showPassword = () => {
        setShowPass(!showPass);
    }
    const showConfirmPassword = () => {
        setShowConfirmPass(!showConfirmPass);
    }

    return (<>
        <section className="login">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6" id="mobile-hide">
                        <div className="form-card">

                            <div className="form-illustration">
                                <img src={process.env.PUBLIC_URL + '/assets/img/medical-logo.png'} alt="logo" className="img-fluid" />
                                <h1>XeoMed</h1>
                                <p>Since then, our team has grown to over 50 experts who conceptualize, and install a robust offering of commercial
                                    services.</p>
                            </div>
                        </div>
                    </div>
                    <div className="signup-mobileshow" id="mobile-show">
                        <form action="" method="post" className="alginbox" onSubmit={(e) => signupAuth(e)} >
                            <div className="login-card register-input">
                                <div className="form-main-heading">
                                    <h1>Signup</h1>
                                    <span>Enter Your Details For Signup</span>
                                </div>
                                <div className="login-input">
                                    <label>First Name</label>

                                    <input type="text" className="login-field" placeholder="FIRST NAME" required name="fName" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="login-input">
                                    <label>Last Name</label>

                                    <input type="text" className="login-field" placeholder="LAST NAME" required name="lName" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="login-input">
                                    <label>Email</label>

                                    <input type="email" className="login-field" placeholder="EMAIL" required name="email" onChange={(e) => handleChange(e)} />
                                    <span className={isEmailExist ? "id-error" : "id-success"}>This email is already exist.</span>
                                </div>
                                <div className="login-input">
                                    <label>Phone</label>

                                    <input type="text" className="login-field" placeholder="PHONE" required name="phone" maxLength="14" value={patientDetails.phone} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="text-danger">{errors.signup}</div>
                                <div className="login-input">
                                    <label>Password</label>

                                    <input type={showPass ? "text" : "password"} className="login-field" placeholder="PASSWORD" required name="password" onChange={(e) => handleChange(e)} />
                                    <div onClick={() => showPassword()} className="input-field-icon">
                                        <img className="imput-field-icon" src={process.env.PUBLIC_URL + '/assets/img/hide-password.png'} id="EYE" alt="icon" onClick={() => showPassword()} />
                                    </div>
                                    <div className="text-danger">{errors.password}</div>
                                </div>
                                <div className="login-input">
                                    <label>Confirm Password</label>

                                    <input type={showConfirmPass ? "text" : "password"} id="pwd" className="login-field" placeholder="CONFIRM PASSWORD" required name="confirmPassword" onChange={(e) => handleChange(e)} />
                                    <div onClick={() => showConfirmPassword()} className="input-field-icon">
                                        <img className="imput-field-icon" src={process.env.PUBLIC_URL + '/assets/img/hide-password.png'} id="EYE" alt="icon" onClick={() => showConfirmPassword()} />
                                    </div>
                                    <div className="text-danger"> {errors.confirmPassword} </div>
                                </div>
                                <div className="find-account login-input">
                                    <div className="link-text">
                                        <span className="keepme-text"> You will recieve code on your phone to complete the signup.  </span>
                                    </div>
                                </div>
                                <div className="name-felx login-input">
                                    <div className="input-col">
                                        <button type="submit" className="login-red-btn" id="goDashboard"> Signup</button>
                                    </div>
                                </div>
                                <div className="find-account">
                                    <div className="link-text">
                                        <span className="keepme-text">Already Have an Account? <Link to="/"  >Login</Link> </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </section>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <section className="OTPScreen">
                    <div className="container-fluid">
                        <form action="" method="post" className="alginbox" onSubmit={(e) => signup(e)} >
                            <div className="OTP-card">
                                <div className="form-main-heading">
                                    <h1>Please Enter The Code To Verify Your Account</h1>
                                    <span>Please Enter Code Sent To Your Phone <span> {maskPhone(authResult?.phone)} </span>  </span>
                                    {/* <span>Please Enter Code Sent To Your Phone <span> {maskPhone(phoneWithCountryCode(patientDetails.phone))} </span>  </span> */}
                                </div>
                                <div className="otp-input">
                                    <div className="App">
                                        <OTPInput
                                            autoFocus
                                            length={6}
                                            className="otpContainer"
                                            inputClassName="otpInput"
                                            onChangeOTP={(otp) => getOTP(otp)}
                                        />
                                    </div>
                                    <div className="text-danger">{otpError}</div>
                                </div>
                                <div className="verify-button">
                                    <button type="submit" className="verify-btn" id="verifyBtn">verify</button>
                                </div>
                                <div className="verify-button">
                                    <button type="button" className="cancel-btn" id="verifyBtn" onClick={() => handleClose()}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </section>
            </Box>
        </Modal>
    </>)
}
