import{
    PRESCRIPTION_DETAILS,
    PATIENT_PRESCRIPTION,
    DRUG_PRICE, 
    COMPLETE_PACKAGE_PRICE,
    INDIVIDUAL_PACKAGE_PRICE

} from './PrescriptionConstants';

export const patientPrescriptionDetails = ()=>{
    const type = PRESCRIPTION_DETAILS;
    return {type: PRESCRIPTION_DETAILS };
};

export const patientPrescription = (patient_id)=>{
    const type = PATIENT_PRESCRIPTION;
    return {type: PATIENT_PRESCRIPTION, payload: patient_id };
};

export const drugPrice = (payload)=>{
    const type = DRUG_PRICE;
    return {type: DRUG_PRICE, payload };
}

export const completePackagePrice = (payload)=>{
    const type = COMPLETE_PACKAGE_PRICE;
    return {type: COMPLETE_PACKAGE_PRICE, payload };
}

export const individualPackagePrice = (payload)=>{
    const type = INDIVIDUAL_PACKAGE_PRICE;
    return {type: INDIVIDUAL_PACKAGE_PRICE, payload };
}

