import axios from 'axios';
import { API_PREFIX } from './config';

const httpClient = axios.create({
  baseURL: API_PREFIX,
  headers: {'Content-Type': 'application/json'},
  // withCredentials: true
});

httpClient.interceptors.request.use(function (config) {
  const tokens = JSON.parse(localStorage.getItem('tokens'));
  const user = localStorage.getItem('loggedInUser');
  if(!config.headers['auth-token']){
    config.headers['auth-token'] =  tokens?.authToken;
  }
  config.headers['user'] =  user;
  return config;
}, function(error){
  return Promise.reject(error)
});

httpClient.interceptors.response.use(function(response){
   return response
  }, async function(error){
      const prevRequest = error?.config;
      if(error?.response.status === 401 && !prevRequest?.sent){
        const tokens = JSON.parse(localStorage.getItem('tokens'));
        prevRequest.sent = true;
        const newAccessToken = await post(`/refresh-token`, {oldRefreshToken: tokens?.refreshToken});
        console.log("res interceptor", newAccessToken)
        prevRequest.headers['auth-token']=newAccessToken[0]?.authToken;
        return httpClient(prevRequest);
      }
      return Promise.reject(error);
});

export const get = async (url, config) => {
  return (await httpClient.get(url, config)).data;
};
export const post = async (url, data) => {
  return (await httpClient.post(url, data)).data;
};

export const put = async (url, data) => {
  return (await httpClient.put(url, data)).data;
};

export const deleteRec = async url => {
  return (await httpClient.delete(url)).data;
};

export const deleteRecWithData = async (url, data) => {
  return (await httpClient({ method: 'delete', url: url, data: data })).data;
};

