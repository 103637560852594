import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as constant from '../../util/constant'
import MaterialTable from "components/Custom/MaterialUITables/MaterialTable";
import DataNotFound from "../DataNotFound";
import { 
    pickupRxAwaitingRecord,
    clearRxAwaitingRecord,

    rxhistoryNotPickupPrescription, 
    rxhistoryPickedupPrescription,
    rxhistoryClearedPrescription 
}
from "../../redux/Actions/RxHistory";

const currentRxAwaitingPickupColumns = [
    { id: 'prescribed_date', label: 'Prescribed Date', minWidth: 170, align: 'center' },
    { id: 'drug_name', label: 'Drug', minWidth: 170, align: 'center', },
    {
        id: 'dose',
        label: 'Dosage',
        minWidth: 100,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'quantity',
        label: 'Quantity',
        minWidth: 100,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'unit',
        label: 'Unit',
        minWidth: 100,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'refill',
        label: 'Refill',
        minWidth: 100,
        align: 'center',
    },
];
const previousRxAwaitingPickupColumns = [
    { id: 'prescribed_date', label: 'Prescribed Date', minWidth: 170, align: 'center', },
    { id: 'drug_name', label: 'Drug Name', minWidth: 100, align: 'center', },
    {
        id: 'pharmacy_name',
        label: 'Pharmacy Name',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
];

const rxHistoryColumns = [
    { id: 'prescribed_date', label: 'Prescribed Date', minWidth: 170, align: 'center'},
    { id: 'drug_name', label: 'Drug Name', minWidth: 100, align: 'center' },
    {
        id: 'pharmacy_location',
        label: 'Location',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'quantity',
        label: 'Quantity',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'pickup_status',
        label: 'Status',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
];
function RxHistory({ notPickupPrescriptions, pickedupPrescriptions, clearedPrescriptions }) {

    console.log("notPickupPrescriptions", notPickupPrescriptions);
    console.log("pickedupPrescriptions", pickedupPrescriptions);
    console.log("clearedPrescriptions", clearedPrescriptions);

    const dispatch = useDispatch();
    const pickupRxAwaitingRecordReducer = useSelector((state) => state.pickupRxAwaitingRecordReducer);
    const clearRxAwaitingRecordReducer = useSelector((state) => state.clearRxAwaitingRecordReducer);

    useEffect(() => {
        if (pickupRxAwaitingRecordReducer?.response) {
            if (pickupRxAwaitingRecordReducer?.response[0].status === constant.UPDATE_SUCCESS) {
                let patient = JSON.parse(localStorage.getItem('loggedInUser'));
                dispatch(rxhistoryNotPickupPrescription(patient.id));
                dispatch(rxhistoryPickedupPrescription(patient.id));
                dispatch(rxhistoryClearedPrescription(patient.id));

            }
        }
    }, [pickupRxAwaitingRecordReducer]);
    
    useEffect(() => {
        if (clearRxAwaitingRecordReducer?.response) {
            if (clearRxAwaitingRecordReducer?.response[0].status === constant.UPDATE_SUCCESS) {
                let patient = JSON.parse(localStorage.getItem('loggedInUser'));
                dispatch(rxhistoryNotPickupPrescription(patient.id));
                dispatch(rxhistoryPickedupPrescription(patient.id));
                dispatch(rxhistoryClearedPrescription(patient.id));

            }
        }
    }, [clearRxAwaitingRecordReducer]);
    
    const clearRxAwaitingPickupRecord = (record) => {
        dispatch(clearRxAwaitingRecord(record.drug_id));
    }

    const pickupRxAwaitingPickupRecord = (record) => {
        console.log(record)
        dispatch(pickupRxAwaitingRecord(record.drug_id));
    }
    if (pickedupPrescriptions.length === 0 && notPickupPrescriptions.length === 0 && clearedPrescriptions.length === 0) {
        return <DataNotFound message={constant.RX_NOT_FOUND_MESSAGE} />;
      }
    return (
        <div className="main-content">
            <div className="content-section">

                <div className="discription">
                    <div className="Individual-package mb-5" >
                        <div className="previous-table">
                            <h2>Current Rx Awaiting PickUp</h2>
                            <div className="filterdata">
                                <div className="searchbar"></div>
                                <div className="no_prescription">
                                    Total Prescription -
                                    <span className="t_no">1</span>
                                </div>
                            </div>
                            <div className="tabledata mt-3 mb-5">
                                <MaterialTable
                                    columns={currentRxAwaitingPickupColumns}
                                    
                                    rows={pickedupPrescriptions}
                                    actions={[{ label: 'Picked up', tooltip: 'Pickup', onClick: (event, rowData) => pickupRxAwaitingPickupRecord(rowData) }]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="Individual-package mb-5">
                        <div className="previous-table">
                            <h2>Previous Rx Awaiting PickUp</h2>
                            <div className="tabledata mt-3 mb-5">
                                <MaterialTable
                                    columns={previousRxAwaitingPickupColumns}
                                    
                                    rows={notPickupPrescriptions}
                                    actions={[
                                        { label: 'Clear', tooltip: 'Clear', onClick: (event, rowData) => clearRxAwaitingPickupRecord(rowData) },
                                        { label: 'Picked up', tooltip: 'Pickup', onClick: (event, rowData) => pickupRxAwaitingPickupRecord(rowData) }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="Individual-package mb-5">
                        <div className="previous-table">
                            <h2>Rx History</h2>
                            <div className="tabledata mt-3 mb-5">
                                <MaterialTable
                                    columns={rxHistoryColumns}
                                   
                                    rows={clearedPrescriptions}
                                    
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default RxHistory;

