import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CollapsibleTable from "components/Custom/MaterialUITables/CollapsibleTable";
import { dateTimeFormator } from "../../util/phoneFormats";
import { zipCode, pharmacyList, individualPharmacyListWithDrugPrice } from "redux/Actions/SearchCriteria";
import { patientPrescription, completePackagePrice, individualPackagePrice } from "redux/Actions/Prescription"
import * as constant from '../../util/constant';
import MaterialTable from "components/Custom/MaterialUITables/MaterialTable";
import DataNotFound from "../DataNotFound";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  borderRadius: '50px',
  //   border: '2px solid #000',
  //   boxShadow: 24,
  //   p: 4,
};
const currentRxActivityColumns = [
  {
    id: 'prescribed_date',
    label: 'Prescribed Date',
    minWidth: 170,
    align: 'center',
    format: (value) => console.log(value),
  },
  { id: 'drug_name', label: 'Drug', minWidth: 100, align: 'center', },
  {
    id: 'dose',
    label: 'Dosage',
    minWidth: 100,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'quantity',
    label: 'Quantity',
    minWidth: 100,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'unit',
    label: 'Unit',
    minWidth: 100,
    align: 'center',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'refill',
    label: 'Refill',
    minWidth: 100,
    align: 'center',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'directions',
    label: 'Direction',
    minWidth: 170,
    align: 'center',
    // format: (value) => value.toFixed(2),
  },
];
const completePackagePriceColumns = [
  { id: 'prescribed_date', label: 'Prescribed Date', minWidth: 170, align: 'center', },
  { id: 'pharmacy_name', label: 'Pharmacy Name', minWidth: 100, align: 'center', },
  { id: 'address', label: 'Pharmacy Location', minWidth: 100, align: 'center', },
  { id: 'price', label: 'Price', minWidth: 170, align: 'center', format: (value) => value.toLocaleString('en-US'), },];

const individualPriceCompColumns = [
  {
    id: 'drug_name',
    numeric: false,
    disablePadding: true,
    label: 'Drug Name',
  },
  {
    id: 'price_range',
    numeric: true,
    disablePadding: false,
    label: 'Price Range',
  },
];



function MyPrescription({ prescriptions }) {

  const dispatch = useDispatch();
  const [cmpOpen, setCmpOpen] = useState(false);
  const [indiOpen, setIndiOpen] = useState(false);
  const [showTables, setShowTables] = useState(false);
  const [coordinates, setCoordinates] = useState({
    "lat": "",
    "long": ""
  });
  const [zip, setZip] = useState("");
  const [searchCriteria, setSearchCriteria] = useState({
    "selectedSearchCriteria": "",
    "value": '',
    "error": "",
  });
  const [openTable, setOpenTable] = useState(0);
  const [compPackPrice, setCompPackPrice] = useState({});
  const [indiPackPrice, setIndiPackPrice] = useState({});

  const [compPackPricePharList, setCompPackPricePharList] = useState([]);
  const [indiPricePharList, setIndiPricePharList] = useState([]);

  const searchCriteriaReducer = useSelector((state) => state.searchCriteriaReducer);
  const pharmacyListReducer = useSelector((state) => state.pharmacyListReducer);
  const individualPharmacyListWithDrugPriceReducer = useSelector((state) => state.individualPharmacyListWithDrugPriceReducer);
  const completePackagePriceReducer = useSelector((state) => state.completePackagePriceReducer);
  const individualPackagePriceReducer = useSelector((state) => state.individualPackagePriceReducer);

  const minBy = (arr, fn) => Math.min(...arr.map(typeof fn === 'function' ? fn : val => val[fn]));
  const maxBy = (arr, fn) => Math.max(...arr.map(typeof fn === 'function' ? fn : val => val[fn]));

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCoordinates({ ...coordinates, "lat": position.coords.latitude, "long": position.coords.longitude });
    });
  }, []);

  useEffect(() => {
    if (searchCriteriaReducer?.response) {
      if (searchCriteriaReducer?.response[0].status === constant.READ_SUCCESS) {
        if (searchCriteriaReducer?.response[0].data.length > 0)
          setZip(searchCriteriaReducer?.response[0].data[0].zip_code);
        setSearchCriteria({ ...searchCriteria, "value": searchCriteriaReducer?.response[0].data[0].zip_code })
      }
    }
  }, [searchCriteriaReducer]);

  useEffect(() => {
    console.log("pharmacyListReducer=====>", pharmacyListReducer)
    if (pharmacyListReducer?.response) {
      if (pharmacyListReducer?.response[0].status === constant.READ_SUCCESS) {

        let temp = [], prescribedDate = "", totalDrugPrice = 0, prescriptionid = null;

        pharmacyListReducer?.response[0].data.pres_date_result[0].forEach(item => {
          totalDrugPrice = totalDrugPrice + parseFloat(item.total_price);
          prescribedDate = item.prescribed_date;
          prescriptionid = item.prescriptionid;
        });

        pharmacyListReducer?.response[0].data.pharmacy_list_result[0].forEach((item) => {
          temp.push({ ...item, "prescriptionid": prescriptionid, "prescribed_date": prescribedDate, "price": (parseFloat(item.price_factor) * parseFloat(totalDrugPrice)).toFixed(2) })
        })

        setCompPackPricePharList(temp)
      }
    }
  }, [pharmacyListReducer]);


  useEffect(() => {
    console.log(individualPharmacyListWithDrugPriceReducer, "===>individualPharmacyListWithDrugPriceReducer")
    if (individualPharmacyListWithDrugPriceReducer?.response) {
      if (individualPharmacyListWithDrugPriceReducer?.response[0].status === constant.READ_SUCCESS) {
        let x = [];

        individualPharmacyListWithDrugPriceReducer?.response[0].data.indi_price_result[0].forEach(elem => {
          let y = [];
          individualPharmacyListWithDrugPriceReducer?.response[0].data.pharmacy_list_result[0].forEach((item) => {
            y.push({ ...item, "price": (parseFloat(elem.quantity) * parseFloat(elem.drug_price) * parseFloat(item.price_factor)).toFixed(2) })
          })

          x.push({ ...elem, "drug_id": elem.id, "price_range": `${minBy(y, z => z.price).toFixed(2)} - ${maxBy(y, z => z.price).toFixed(2)}`, "history": y });
          y = [];
        });

        setIndiPricePharList(x)
      }
    }
  }, [individualPharmacyListWithDrugPriceReducer]);

  useEffect(() => {
    if (completePackagePriceReducer?.response) {
      if (completePackagePriceReducer?.response[0].status === constant.UPDATE_SUCCESS) {
        handleCmpClose();
        let patient = JSON.parse(localStorage.getItem('loggedInUser'));

        dispatch(patientPrescription(patient.id));
        dispatch(pharmacyList({
          "selectedSearchCriteria": searchCriteria.selectedSearchCriteria,
          "zip": searchCriteria.value,
          "patient_id": patient.id
        }));
        dispatch(individualPharmacyListWithDrugPrice({
          "selectedSearchCriteria": searchCriteria.selectedSearchCriteria,
          "zip": searchCriteria.value,
          "patient_id": patient.id
        }));
      }
    }
  }, [completePackagePriceReducer]);

  useEffect(() => {
    if (individualPackagePriceReducer?.response) {
      if (individualPackagePriceReducer?.response[0].status === constant.UPDATE_SUCCESS) {
        handleIndiClose();
        let patient = JSON.parse(localStorage.getItem('loggedInUser'));

        dispatch(patientPrescription(patient.id));
        dispatch(pharmacyList({
          "selectedSearchCriteria": searchCriteria.selectedSearchCriteria,
          "zip": searchCriteria.value,
          "patient_id": patient.id
        }));
        dispatch(individualPharmacyListWithDrugPrice({
          "selectedSearchCriteria": searchCriteria.selectedSearchCriteria,
          "zip": searchCriteria.value,
          "patient_id": patient.id
        }));
      }
    }
  }, [individualPackagePriceReducer]);

  const handleCmpOpen = () => setCmpOpen(true);
  const handleCmpClose = () => {
    setCmpOpen(false);
  }
  const handleIndiOpen = () => setIndiOpen(true);
  const handleIndiClose = () => {
    setIndiOpen(false);
  }



  const handleSearchCriteria = (e) => {
    setSearchCriteria({ ...searchCriteria, "selectedSearchCriteria": e.target.value, "error": "" });
  }

  const handleZipCode = (e) => {
    dispatch(zipCode({
      "lat": "30.357756",
      "long": "-81.505829"
    }));
  }

  const handleLocationZip = (e) => {
    setSearchCriteria({ ...searchCriteria, "value": e.target.value });
  }

  const handleSubmit = () => {

    if (!searchCriteria.selectedSearchCriteria) {
      setSearchCriteria({ ...searchCriteria, "error": "Please select one of the field." });
      return
    }
    if (searchCriteria.selectedSearchCriteria === "IWillPickLocation" && searchCriteria.value === "") {
      setSearchCriteria({ ...searchCriteria, "error": "Please enter location zip code." });
      return
    }

    let patient = JSON.parse(localStorage.getItem("loggedInUser"));
    dispatch(pharmacyList({
      "selectedSearchCriteria": searchCriteria.selectedSearchCriteria,
      "zip": searchCriteria.value,
      "patient_id": patient.id
    }))

    dispatch(individualPharmacyListWithDrugPrice({
      "selectedSearchCriteria": searchCriteria.selectedSearchCriteria,
      "zip": searchCriteria.value,
      "patient_id": patient.id
    }))
  }


  const selectCompletePackagePrice = (rowData) => {
    console.log("hm tuesday", rowData)
    setCompPackPrice({
      "prescription_id": rowData.prescriptionid,
      "pharmacy_list_seq": rowData.pharmacy_list_seq,
      "pharmacy_name": rowData?.pharmacy_name
    });
    handleCmpOpen();
  }
  const submitSelectedCompletePackagePrice = (e) => {
    e.preventDefault();
    dispatch(completePackagePrice(compPackPrice));
  }

  const selectIndividualPrice = (e, value) => {
    setIndiPackPrice({
      "drug_id": value.drug_id,
      "pharmacy_list_seq": value.pharmacy_list_seq,
      "pharmacy_name": value?.pharmacy_name
    })
    handleIndiOpen();
  }
  const submitSelectedIndividualPrice = (e) => {
    e.preventDefault();
    dispatch(individualPackagePrice(indiPackPrice));
  }

  if (prescriptions.length === 0) {
    return <DataNotFound message={constant.RX_NOT_FOUND_MESSAGE} />;
  }

  return (
    <div className="main-content">

      <div className="content-section">

        <div className="discription">
          <h2>Current Rx Activity</h2>
          <h5>You Have {prescriptions.length} New Prescription</h5>
          
          <div className="filterdata">
            <div className="searchbar">
            </div>
            <div className="no_prescription">
              Total Prescription -
              <span className="t_no">{prescriptions.length}</span>
              
            </div>
          </div>
          <div className="Individual-package mb-5">
            <div className="previous-table">
              <div className="tabledata mt-3 mb-5">
                <MaterialTable
                  columns={currentRxActivityColumns}
                  rows={prescriptions}
                />
              </div>
            </div>
          </div>




          <div className="search-location mb-5">
            <h2>Search</h2>
            <div className="row mt-4">

              <div className="col-lg-3">
                <div className="form-check">
                  <input className="form-check-input" type="radio" value="" id="flexCheckDefault1" name="location" value="HomeAddress" onClick={(e) => handleSearchCriteria(e)} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Home Address
                  </label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-check">
                  <input className="form-check-input" type="radio" value="" id="flexCheckDefault2" name="location" value="CurrentLoaction" onClick={(e) => handleSearchCriteria(e)} onChange={(e) => handleZipCode(e)} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Current Location
                  </label>
                  {(searchCriteria.selectedSearchCriteria === "CurrentLoaction") &&
                    <div>
                      <p>{zip ? zip : `No Zip Code available for this location`} </p>
                    </div>
                  }

                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-check">
                  <input className="form-check-input" type="radio" value="" id="flexCheckDefault3" name="location" value="IWillPickLocation" onClick={(e) => handleSearchCriteria(e)} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    I Will Pick Location
                  </label>
                  {(searchCriteria.selectedSearchCriteria === "IWillPickLocation") &&
                    <div className="mb-3">
                      <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" maxLength="5" placeholder="Enter location zip code" onChange={(e) => handleLocationZip(e)} />
                    </div>
                  }
                </div>
              </div>

              <div className="col-lg-2 text-center ">
                <button type="submit" className="btn btn-primary" onClick={handleSubmit} >Submit</button>
              </div>
            </div>
            <div className="text-danger">{searchCriteria.error}</div>
          </div>


          <div>
            {compPackPricePharList.length > 0 && <div className="complete-pakage mb-5">
              <div className="previous-table">
                <h2>Complete Package Price</h2>

                <div className="tabledata mt-3 mb-5">
                  <MaterialTable
                    columns={completePackagePriceColumns}
                    rows={compPackPricePharList}
                    actions={[{ label: 'Select', tooltip: 'Select', onClick: (event, rowData) => selectCompletePackagePrice(rowData) }]}
                  />
                </div>
              </div>
            </div>}

            {(indiPricePharList.length > 0 && compPackPricePharList.length > 0) &&
              <div className="Individual-package mb-5">
                <div className="previous-table">
                  <h2>Individual Price Comparison </h2>
                  <div className="tabledata mt-3 mb-5">
                    <CollapsibleTable
                      headCells={individualPriceCompColumns}
                      rows={indiPricePharList}
                      onRowSelect={selectIndividualPrice}
                    />
                  </div>
                </div>
              </div>
            }
            {(compPackPricePharList.length === 0) &&
              <div className="Individual-package mb-5">
                <div className="previous-table">
                  <h4>No Pharmacy found </h4>
                </div>
              </div>
            }
          </div>

        </div>
      </div>
      <Modal
        open={cmpOpen}
        onClose={handleCmpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <section className="OTPScreen">
            <div className="container-fluid">
              <form action="#" className="alginbox" onSubmit={(e) => submitSelectedCompletePackagePrice(e)} >
                <div className="OTP-card">
                  <div className="form-main-heading package-pri">
                    <h1>Your prescription will be sent to {compPackPrice?.pharmacy_name}, please confirm. !</h1>
                  </div>

                  <div className="verify-button">
                    <button type="submit" className="verify-btn" id="verifyBtn">yes</button>
                  </div>
                  <div className="verify-button">
                    <button type="button" className="cancel-btn" id="cancelBtn" onClick={() => handleCmpClose()}>No</button>
                  </div>
                </div>
              </form>
            </div>

          </section>
        </Box>
      </Modal>
      <Modal
        open={indiOpen}
        onClose={handleIndiClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <section className="OTPScreen">
            <div className="container-fluid">
              <form action="#" className="alginbox" onSubmit={(e) => submitSelectedIndividualPrice(e)} >
                <div className="OTP-card">
                  <div className="form-main-heading package-pri">
                    <h1>Your prescription will be sent to {indiPackPrice?.pharmacy_name}, please confirm!</h1>
                  </div>

                  <div className="verify-button">
                    <button type="submit" className="verify-btn" id="verifyBtn">yes</button>
                  </div>
                  <div className="verify-button">
                    <button type="button" className="cancel-btn" id="cancelBtn" onClick={() => handleIndiClose()}>No</button>
                  </div>
                </div>
              </form>
            </div>

          </section>
        </Box>
      </Modal>
    </div>
  )
}

export default MyPrescription;