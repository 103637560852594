import Main from "components/Layout/Main";
import RxHistory from "components/RxHistory";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as constant from '../../util/constant';
import {
    rxhistoryNotPickupPrescription,
    rxhistoryPickedupPrescription,
    rxhistoryClearedPrescription
} from "../../redux/Actions/RxHistory";

function RxHistoryContainer() {
    const dispatch = useDispatch();
    const [notPickupPrescriptions, setNotPickupPrescriptions] = useState([]);
    const [pickedupPrescriptions, setPickedupPrescriptions] = useState([]);
    const [clearedPrescriptions, setClearedPrescriptions] = useState([]);

    const rxhistoryNotPickupPrescriptionReducer = useSelector((state) => state.rxhistoryNotPickupPrescriptionReducer);
    const rxhistoryPickedupPrescriptionReducer = useSelector((state) => state.rxhistoryPickedupPrescriptionReducer);
    const rxhistoryClearedPrescriptionReducer = useSelector((state) => state.rxhistoryClearedPrescriptionReducer);

    useEffect(() => {
        let patient = JSON.parse(localStorage.getItem('loggedInUser'));
        dispatch(rxhistoryPickedupPrescription(patient.id));
        dispatch(rxhistoryNotPickupPrescription(patient.id));
        dispatch(rxhistoryClearedPrescription(patient.id));
    }, []);

    useEffect(() => {
        if (rxhistoryNotPickupPrescriptionReducer?.response) {
            if (rxhistoryNotPickupPrescriptionReducer?.response[0].status === constant.READ_SUCCESS) {
                setNotPickupPrescriptions(rxhistoryNotPickupPrescriptionReducer.response[0].data);
            }
        }
    }, [rxhistoryNotPickupPrescriptionReducer]);
    useEffect(() => {
        if (rxhistoryPickedupPrescriptionReducer?.response) {
            if (rxhistoryPickedupPrescriptionReducer?.response[0].status === constant.READ_SUCCESS) {
                setPickedupPrescriptions(rxhistoryPickedupPrescriptionReducer.response[0].data);
            }
        }
    }, [rxhistoryPickedupPrescriptionReducer]);

    useEffect(() => {
        if (rxhistoryClearedPrescriptionReducer?.response) {
            if (rxhistoryClearedPrescriptionReducer?.response[0].status === constant.READ_SUCCESS) {
                setClearedPrescriptions(rxhistoryClearedPrescriptionReducer.response[0].data);
            }
        }
    }, [rxhistoryClearedPrescriptionReducer]);
    return (
        <Main>
            <RxHistory
                notPickupPrescriptions={notPickupPrescriptions}
                pickedupPrescriptions={pickedupPrescriptions}
                clearedPrescriptions={clearedPrescriptions}
            />
        </Main>
    )
}

export default RxHistoryContainer