import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function PrivateRoute(props) {
  const { Component } = props;
  const navigate = useNavigate();
  useEffect(() => {
    const login = localStorage.getItem("tokens");
    if (login === null) {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <Component />
    </div>
  );
}
export default PrivateRoute;
