export function usPhoneFormat(phone) {
    phone = phone.toString();
    phone = phone.replace(/\D/g, '');
    const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})(\d{0,4})$/);
    if (match) {
        phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}${match[4] ? ' x' : ''}${match[4]}`;
    }
    return phone;
}

export function phoneWithCountryCode(phone){
    return "+91" + phone.replace(/[() -]/g, "");
}

export function dateTimeFormator(str){
    if(!str) return;
    let temp = str.split('T');
    return new Intl.DateTimeFormat('en-US').format(new Date(str)) +" "+ temp[1].slice(0, 8);
}

export function maskPhone(temp){
    if(temp){
        let str = temp.toString();
        return str.replace(/.(?=\d{4})/g, "*")
    }

}