import * as types from '../../Actions/RxHistory/RxHistoryConstants';

export function rxhistoryNotPickupPrescriptionReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.RXHISTORY_NOTPICKUP_PRESCRIPTION:
            return { loading: true, action };
        case types.RXHISTORY_NOTPICKUP_PRESCRIPTION_SUCCESS:
            return { loading: true, response };
        case types.RXHISTORY_NOTPICKUP_PRESCRIPTION_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};
export function rxhistoryPickedupPrescriptionReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.RXHISTORY_PICKEDUP_PRESCRIPTION:
            return { loading: true, action };
        case types.RXHISTORY_PICKEDUP_PRESCRIPTION_SUCCESS:
            return { loading: true, response };
        case types.RXHISTORY_PICKEDUP_PRESCRIPTION_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};
export function rxhistoryClearedPrescriptionReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.RXHISTORY_CLEARED_PRESCRIPTION:
            return { loading: true, action };
        case types.RXHISTORY_CLEARED_PRESCRIPTION_SUCCESS:
            return { loading: true, response };
        case types.RXHISTORY_CLEARED_PRESCRIPTION_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function pickupRxAwaitingRecordReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.PICKUP_RX_AWAITING_RECORD:
            return { loading: true, action };
        case types.PICKUP_RX_AWAITING_RECORD_SUCCESS:
            return { loading: true, response };
        case types.PICKUP_RX_AWAITING_RECORD_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function clearRxAwaitingRecordReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.CLEAR_RX_AWAITING_RECORD:
            return { loading: true, action };
        case types.CLEAR_RX_AWAITING_RECORD_SUCCESS:
            return { loading: true, response };
        case types.CLEAR_RX_AWAITING_RECORD_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};


