import React from 'react';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar } from '@mui/material';
import { Toolbar } from '@mui/material';
import { Hidden } from '@mui/material';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import { makeStyles } from '@mui/styles';
import routes from 'routes';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    color: theme.palette.white
  },
  logo: {
    width: theme.spacing(7)
  },
  logoTitle: {
    color: theme.palette.white,
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

const Topbar = (props) => {
  const navigate = useNavigate();
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const patientLoginReducer = useSelector((state) => state.patientLoginReducer);

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const signOut = () => {
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('tokens');
    console.log("remove", routes.logIn.path)
    navigate(routes.logIn.path, { replace: true });
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}

    >
      <Toolbar>
        <RouterLink to='/'>
          <img className={classes.logo} alt='Logo' src={process.env.PUBLIC_URL + '/assets/img/medical-logo.png'} />
        </RouterLink>
        <RouterLink to='/' style={{ textDecoration: 'none' }}>
          <Typography className={classes.logoTitle}>XeoMed</Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden lgUp>


          <IconButton
            color='inherit'
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>

        </Hidden>
        <Hidden >
          <Typography color='white' >
            {loggedInUser ? `${loggedInUser?.fName} ${loggedInUser?.lName}` : ""}
          </Typography>
          <Tooltip title='SignOut' placement='bottom' >
            <IconButton
              className={classes.signOutButton}
              color='inherit'
              onClick={() => signOut()}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
