
export const RXHISTORY_NOTPICKUP_PRESCRIPTION = 'RXHISTORY_NOTPICKUP_PRESCRIPTION';
export const RXHISTORY_NOTPICKUP_PRESCRIPTION_SUCCESS ='RXHISTORY_NOTPICKUP_PRESCRIPTION_SUCCESS';
export const RXHISTORY_NOTPICKUP_PRESCRIPTION_ERROR = 'RXHISTORY_NOTPICKUP_PRESCRIPTION_ERROR';

export const RXHISTORY_PICKEDUP_PRESCRIPTION = 'RXHISTORY_PICKEDUP_PRESCRIPTION';
export const RXHISTORY_PICKEDUP_PRESCRIPTION_SUCCESS ='RXHISTORY_PICKEDUP_PRESCRIPTION_SUCCESS';
export const RXHISTORY_PICKEDUP_PRESCRIPTION_ERROR = 'RXHISTORY_PICKEDUP_PRESCRIPTION_ERROR';

export const RXHISTORY_CLEARED_PRESCRIPTION = 'RXHISTORY_CLEARED_PRESCRIPTION';
export const RXHISTORY_CLEARED_PRESCRIPTION_SUCCESS ='RXHISTORY_CLEARED_PRESCRIPTION_SUCCESS';
export const RXHISTORY_CLEARED_PRESCRIPTION_ERROR = 'RXHISTORY_CLEARED_PRESCRIPTION_ERROR';

export const CLEAR_RX_AWAITING_RECORD = 'CLEAR_RX_AWAITING_RECORD';
export const CLEAR_RX_AWAITING_RECORD_SUCCESS ='CLEAR_RX_AWAITING_RECORD_SUCCESS';
export const CLEAR_RX_AWAITING_RECORD_ERROR = 'CLEAR_RX_AWAITING_RECORD_ERROR';

export const PICKUP_RX_AWAITING_RECORD = 'PICKUP_RX_AWAITING_RECORD';
export const PICKUP_RX_AWAITING_RECORD_SUCCESS ='PICKUP_RX_AWAITING_RECORD_SUCCESS';
export const PICKUP_RX_AWAITING_RECORD_ERROR = 'PICKUP_RX_AWAITING_RECORD_ERROR';