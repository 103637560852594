
function ChangePassword() {
    return (
    
        <div class="main-content">
		<div class="content-section">
			<div class="discription">
				<h2>Change Password</h2>
				<div class="update-password">
				<div class="row">
					<div class="col-lg-6">						
							<div class="change-password">
								<div class="login-input">
									<label>Old Password</label>
									<img src={process.env.PUBLIC_URL + 'assets/img/lock.png'} alt="lock" class="lock" />
                             
									<input type="password" id="pwd" class="eye-pd"/>
									<div onclick="login()" class="input-field-icon">
										<img class="imput-field-icon" src={process.env.PUBLIC_URL + 'assets/img/hide-password.png'} id="EYE" alt="icon"/>
                                     
									</div>
								</div>
								<div class="login-input">
									<label>New Password</label>
									<img src={process.env.PUBLIC_URL + 'assets/img/lock.png'} alt="lock" class="lock" />
                             
									<input type="password" id="pwd" class="eye-pd"/>
									<div onclick="login()" class="input-field-icon">
										<img class="imput-field-icon" src={process.env.PUBLIC_URL + 'assets/img/hide-password.png'} id="EYE" alt="icon"/>
                                     
									</div>
								</div>
								<div class="login-input">
									<label>Confirm Password</label>
									<img src={process.env.PUBLIC_URL + 'assets/img/lock.png'}  alt="lock" class="lock"/>
									<input type="password" id="pwd" class="eye-pd"/>
									<div onclick="login()" class="input-field-icon">
										<img class="imput-field-icon" src={process.env.PUBLIC_URL + 'assets/img/hide-password.png'} id="EYE"
											alt="icon"/>
                                          
									</div>
								</div>

								<div class="name-felx login-input">
									<div class="input-col">
										<button type="button" class="login-red-btn"> Change Password</button>
									</div>

								</div>
								
							</div>
						</div>
					
					<div class="col-lg-6">
						<h3 class="pmc">Password Must Contain</h3>
						<p>At least 1 upper case letter (A-Z)</p>
						<p>At least 1 number (0-9)</p>
						<p>At least 8 characters</p>
					</div>
					</div>
				</div>

			</div>
		</div>
	</div>
                )
}

                export default ChangePassword
