export const PRESCRIPTION_DETAILS = 'PRESCRIPTION_DETAILS';
export const PRESCRIPTION_DETAILS_SUCCESS ='PRESCRIPTION_DETAILS_SUCCESS';
export const PRESCRIPTION_DETAILS_ERROR = 'PRESCRIPTION_DETAILS_ERROR';

export const PATIENT_PRESCRIPTION = 'PATIENT_PRESCRIPTION';
export const PATIENT_PRESCRIPTION_SUCCESS ='PATIENT_PRESCRIPTION_SUCCESS';
export const PATIENT_PRESCRIPTION_ERROR = 'PATIENT_PRESCRIPTION_ERROR';

export const DRUG_PRICE = 'DRUG_PRICE';
export const DRUG_PRICE_SUCCESS ='DRUG_PRICE_SUCCESS';
export const DRUG_PRICE_ERROR = 'DRUG_PRICE_ERROR';

export const COMPLETE_PACKAGE_PRICE = 'COMPLETE_PACKAGE_PRICE';
export const COMPLETE_PACKAGE_PRICE_SUCCESS ='COMPLETE_PACKAGE_PRICE_SUCCESS';
export const COMPLETE_PACKAGE_PRICE_ERROR = 'COMPLETE_PACKAGE_PRICE_ERROR';

export const INDIVIDUAL_PACKAGE_PRICE = 'INDIVIDUAL_PACKAGE_PRICE';
export const INDIVIDUAL_PACKAGE_PRICE_SUCCESS ='INDIVIDUAL_PACKAGE_PRICE_SUCCESS';
export const INDIVIDUAL_PACKAGE_PRICE_ERROR = 'INDIVIDUAL_PACKAGE_PRICE_ERROR';