


/* ######################## STATUS CODES ###################### */

export const ERROR = 1;
export const NOT_FOUND = 2;
export const READ_ERROR = 3;
export const CREATE_ERROR = 4;
export const UPDATE_ERROR = 5;
export const DELETE_ERROR = 6;
export const READ_SUCCESS = 7;
export const CREATE_SUCCESS = 8;
export const UPDATE_SUCCESS = 9;
export const DELETE_SUCCESS = 10;
export const EMAIL_SENT = 11;
export const EMAIL_SENDING_FAILED = 12;
export const DUPLICATE_RECORD = 13;

export const VALID_LOGIN = 14;

export const INVALID_LOGIN = 15;
export const FILE_UPLOADED = 16;
export const DUPLICATE_EMAIL = 17;
export const IN_USE_CANNOT_REMOVE = 18;
export const ACCOUNT_INACTIVE = 19;
export const VALID_2FA = 20;
export const VERIFIED_2FA = 21
export const NOT_VERIFIED_2FA = 22
export const ENCODED_READ_SUCCESS = 23;
export const VALID_EMAIL = 24;
export const MESSAGE_SENT = 25;
export const VALID_SIGNUP = 26;
export const INVALID_SIGNUP = 27;

export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_ERROR = 500;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_NOT_FOUND = 404;
export const HTTP_STATUS_BAD_REQUEST = 400;


/* ######################## Messages ###################### */
export const SIGNED_UP_MESSAGE = 'You have signed up successfully!';
export const UPDATE_PROFILE_MESSAGE = 'Your profile is updated successfully!';
export const RX_NOT_FOUND_MESSAGE = 'You have no prescriptions!'
