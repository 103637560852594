import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
    h1: {
        fontSize: '2rem',
        lineHeight: '1.2',
    }
}));

function DataNotFound({message}) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component='h1'>{message}</Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default DataNotFound;
