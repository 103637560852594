import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';
import MedicationIcon from '@mui/icons-material/Medication';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import routes from 'routes';


import { SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      // marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: '#B5C6E6',
    // backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  logo: {
    width: '100%',
    margin: '1rem auto',
    textAlign: 'center'
},
logo_img: {
  width: '55px',
  margin: 'auto'
},
logo_h1: {
  fontSize: '1.6rem',
  color: '#fff',
  fontWeight: '500'
}

}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const tokens = localStorage.getItem("tokens");
  let isLoggedIn = false;
  if(tokens){
    isLoggedIn = true;
  }


  const classes = useStyles();
  let pages = [];
  if(isLoggedIn){
    pages = [
      {
        title: routes.myPrescriptions.menuTitle,
        href: routes.myPrescriptions.path,
        icon: <MedicationIcon />
      },
      {
        title: routes.rxHistory.menuTitle,
        href: routes.rxHistory.path,
        icon: <ManageHistoryIcon />
      },
      {
        title: routes.myProfile.menuTitle,
        href: routes.myProfile.path,
        icon: <PersonIcon />
      },
      {
        title: routes.changePassword.menuTitle,
        href: routes.changePassword.path,
        icon: <PasswordIcon />
      }
    ];
  }
  else{
    pages = [
      {
        title: routes.myPrescriptions.menuTitle,
        href: routes.myPrescriptions.path,
        icon: <MedicationIcon />
      },
      {
        title: routes.rxHistory.menuTitle,
        href: routes.rxHistory.path,
        icon: <ManageHistoryIcon />
      },
      {
        title: routes.logIn.menuTitle,
        href: routes.logIn.path,
        icon: <PersonIcon />
      },
    ];
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >

      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
    <div className="mt-64">
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
           </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
