
function MyProfile() {
	let patient = JSON.parse(localStorage.getItem('loggedInUser'));

	return (
		<div className="main-content">

			<div className="content-section">

				<div className="discription">
					<h2>My Profile</h2>
					<div className="profile">
						<div className="dv">
							<div className="info">
								<img src={process.env.PUBLIC_URL + 'assets/img/user.svg'} className="username" alt="Name" />
								<label>Name</label>
								<p>{patient.fName} {patient.lName}</p>
							</div>

							<div className="info">
								<img src={process.env.PUBLIC_URL + 'assets/img/phone.svg'} className="phone" alt="Phone" />
								<label>Contact</label>
								<p>{patient.phone}</p>
							</div>
							<div className="info">
								<img src={process.env.PUBLIC_URL + 'assets/img/email.svg'} className="email" alt="Email" />
								<label>Email</label>
								<p>{patient.email}</p>
							</div>
						</div>
						<div className="dv">
							<div className="info">
								<img src={process.env.PUBLIC_URL + 'assets/img/home.svg'} className="home" alt="address" />
								<label>Address</label>
								<p>Mr John Smith. 132, My Street, Kingston,<br /> New York 12401.</p>
							</div>
							<div className="info">
								<button className="edit-profile">Edit Profile</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MyProfile
