
import {
    ZIPCODE,
    PHARMACY_LIST,
    INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE
} from './SearchCriteriaConstants';

export const zipCode = (payload)=>{
    const type = ZIPCODE;
    return {type: ZIPCODE, payload };
}

export const pharmacyList = (payload)=>{
    const type = PHARMACY_LIST;
    return {type: PHARMACY_LIST, payload };
}

export const individualPharmacyListWithDrugPrice = (payload)=>{
    const type = INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE;
    return {type: INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE, payload };
}