import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import OTPInput from 'components/common/OTPInput.js'
import * as constant from '../../../util/constant'
import '../Signup/Signup.css'
import { maskPhone } from '../../../util/phoneFormats'

import * as api from "../../../util/api"
import { apiRoutes } from '../../../routes';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    borderRadius: '50px',
    //   border: '2px solid #000',
    //   boxShadow: 24,
    //   p: 4,
};

export default function Login() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const patientId = searchParams.get("patientId")
    const [open, setOpen] = useState(false);
    const [otp, setOtp] = useState("");
    const [credentials, setCredentials] = useState({
        "email": "",
        "password": "",
    });
    const [otpError, setOtpError] = useState("");
    const [loginError, setLoginError] = useState("");
    const [authenticatedUser, setAuthenticatedUser] = useState({})
    const [showPass, setShowPass] = useState(false);
    const isAuth = JSON.parse(localStorage.getItem("tokens"))

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOtpError("");
        setOpen(false);
    }

    const handleChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        })
    }
    const loginAuth = (e) => {
        e.preventDefault();
        setLoginError("");
        api.post(`${apiRoutes.loginAuth}`, credentials).then((result) => {
            if (result[0].status === constant.MESSAGE_SENT) {
                setAuthenticatedUser(result[0].data);
                handleOpen();
            }
            if (result[0].status === constant.INVALID_LOGIN) {
                setLoginError("Invalid credentials!");
            }
        }).catch(err => console.log(err))
    }

    const login = (e) => {
        e.preventDefault();
        if (otp === "") {
            setOtpError("Please enter code!");
            return;
        }
        // else if (authenticatedUser.otp === otp) {

        api.post(`${apiRoutes.login}`, { ...credentials, otp }).then((result) => {
            if (result[0].status === constant.VALID_LOGIN) {
                localStorage.setItem("loggedInUser", JSON.stringify(result[0].data.user));
                localStorage.setItem("tokens", JSON.stringify(result[0].data.tokens));
                handleClose();
                navigate("/myprescriptions");
            } else {

            }
        }).catch(err => console.log(err))

        // }else{
        //     setOtpError("Please enter valid code!");
        //         return;
        // }

    }

    const getOTP = (otp) => {
        setOtp(otp)
    }

    const showPassword = () => {
        setShowPass(!showPass);
    }
    const continueAsGuest = () =>{
        localStorage.setItem("loggedInUser", JSON.stringify({"id":patientId,"fName":"Guest", "lName":"", "email":"","phone":""}));
        navigate("/myprescriptions");
    }
    const signup = () =>{
        navigate("/signup",{state:{patientId}});
    }


    useEffect(() => {
        if (!isAuth) {
            handleClose()
        }
    }, [isAuth]);

    return (<>
        <section className="login">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6" id="mobile-hide">
                        <div className="form-card">
                            <div className="form-illustration">
                                <img src={process.env.PUBLIC_URL + '/assets/img/medical-logo.png'} alt="logo" className="img-fluid" />
                                <h1>XeoMed</h1>
                                <p>Since then, our team has grown to over 50 experts who conceptualize, and install a robust offering of commercial
                                    services.</p>
                            </div>
                        </div>
                    </div>
                    <div className="signup-mobileshow login-eye" id="mobile-show">
                        <form action="" method="post" className="alginbox" onSubmit={(e) => loginAuth(e)}>
                            <div className="login-card">
                                <div className="form-main-heading">
                                    <h1>Login</h1>
                                    <span>Feel free to login any time</span>
                                </div>
                                <div className="login-input">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/email.png'} alt="email" className="email-icon" />
                                    <input type="email" className="login-field" placeholder="EMAIL" required name="email" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="login-input">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/lock.png'} alt="lock" className="lock" />
                                    <input type={showPass ? "text" : "password"} id="pwd" className="eye-pd" placeholder="PASSWORD" required name="password" onChange={(e) => handleChange(e)} />
                                    <div className="input-field-icon">
                                        <img className="imput-field-icon" src={process.env.PUBLIC_URL + '/assets/img/hide-password.png'} id="EYE" alt="icon" onClick={() => showPassword()} />
                                    </div>
                                    <div className="text-danger">{loginError}</div>
                                </div>
                                <div className="forgot-pwd">
                                    <div className="keepme">
                                        <label className="label-rel">
                                            <span className="keepme-text">Remember me</span>
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>

                                    </div>
                                    <div className="link-text">
                                        <a href="forgot-password.html">Forgot Password?</a>
                                    </div>
                                </div>
                                <div className="name-felx login-input">
                                    <div className="input-col">
                                        <button type="submit" className="login-red-btn" id="goDashboard"> Login</button>
                                    </div>

                                </div>
                                <div className="name-felx login-input">
                                    <div className="input-col">
                                    <button type="button" onClick={signup} className="login-red-btn" id="goSignup"> Signup</button>
                                    </div>
                                </div>
                                {patientId ? 
                                <div className="name-felx login-input">
                                    <div className="input-col">
                                    <button type="button" onClick={continueAsGuest} className="login-red-btn" id="goPrescription"> Continue as Guest</button>
                                    </div>
                                </div> : null
                                }
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </section>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <section className="OTPScreen">
                    <div className="container-fluid">
                        <form action="" method="post" className="alginbox" onSubmit={(e) => login(e)} >
                            <div className="OTP-card">
                                <div className="form-main-heading">
                                    <h1>Please Enter Code To Verify Your Account</h1>
                                    <span>Please Enter Code Sent To Your Phone {maskPhone(authenticatedUser.phone)} </span>
                                </div>
                                <div className="otp-input">
                                    <div className="App">
                                        <OTPInput
                                            autoFocus
                                            length={6}
                                            className="otpContainer"
                                            inputClassName="otpInput"
                                            onChangeOTP={(otp) => getOTP(otp)}
                                        />
                                    </div>
                                    <div className="text-danger">{otpError}</div>
                                </div>
                                <div className="verify-button">
                                    <button type="submit" className="verify-btn" id="verifyBtn">verify</button>
                                </div>
                                <div className="verify-button">
                                    <button type="button" className="cancel-btn" id="cancelBtn" onClick={() => handleClose()}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </section>
            </Box>
        </Modal>
    </>)
}
