import { combineReducers } from "redux";

import {
    patientSignupAuthReducer,
    patientSignupReducer,
    patientLoginAuthReducer,
    patientLoginReducer,
    updatePatientReducer,
    updatePatientProfileReducer
} from "../Reducers/AuthReducer/authReducer.js";

import{
    patientPrescriptionDetailsReducer,
    patientPrescriptionReducer,
    completePackagePriceReducer,
    individualPackagePriceReducer,
} from "../Reducers/PrescriptionReducer/prescriptionReducer.js";

import {

    rxhistoryNotPickupPrescriptionReducer,
    rxhistoryPickedupPrescriptionReducer,
    rxhistoryClearedPrescriptionReducer,
    clearRxAwaitingRecordReducer,
    pickupRxAwaitingRecordReducer
} from "../Reducers/RxHistoryReducer/rxHistoryReducer.js";

import {
    searchCriteriaReducer,
    pharmacyListReducer,
    individualPharmacyListWithDrugPriceReducer
} from "../Reducers/SearchCriteria/searchCriteriaReducer.js";


const rootReducer = combineReducers({
    patientSignupAuthReducer,
    patientSignupReducer,
    patientLoginAuthReducer,
    patientLoginReducer,
    updatePatientReducer,
    updatePatientProfileReducer,

    patientPrescriptionDetailsReducer,
    patientPrescriptionReducer,
    completePackagePriceReducer,
    individualPackagePriceReducer,

    rxhistoryNotPickupPrescriptionReducer,
    rxhistoryPickedupPrescriptionReducer,
    rxhistoryClearedPrescriptionReducer,
    clearRxAwaitingRecordReducer,
    pickupRxAwaitingRecordReducer,

    searchCriteriaReducer,
    pharmacyListReducer,
    individualPharmacyListWithDrugPriceReducer,

})

export default rootReducer;