import Main from "components/Layout/Main"
import MyProfile from "components/MyProfile"
function MyProfileContainer(){

    return(
        <Main>
            <MyProfile/>
        </Main>
    )
}

export default MyProfileContainer