import * as types from '../../Actions/Prescription/PrescriptionConstants';

export function patientPrescriptionDetailsReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.PRESCRIPTION_DETAILS:
            return { loading: true, action };
        case types.PRESCRIPTION_DETAILS_SUCCESS:
            return { loading: true, response };
        case types.PRESCRIPTION_DETAILS_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function patientPrescriptionReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.PATIENT_PRESCRIPTION:
            return { loading: true, action };
        case types.PATIENT_PRESCRIPTION_SUCCESS:
            return { loading: true, response };
        case types.PATIENT_PRESCRIPTION_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function completePackagePriceReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.COMPLETE_PACKAGE_PRICE:
            return { loading: true, action };
        case types.COMPLETE_PACKAGE_PRICE_SUCCESS:
            return { loading: true, response };
        case types.COMPLETE_PACKAGE_PRICE_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function individualPackagePriceReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.INDIVIDUAL_PACKAGE_PRICE:
            return { loading: true, action };
        case types.INDIVIDUAL_PACKAGE_PRICE_SUCCESS:
            return { loading: true, response };
        case types.INDIVIDUAL_PACKAGE_PRICE_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};
