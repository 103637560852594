import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updatePatientProfile } from "../../../redux/Actions/Auth";
import * as constant from '../../../util/constant';
import {successToast} from '../../../util/toast'
import * as api from "../../../util/api";
import { apiRoutes } from '../../../routes';

export default function UpdateProfile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [patientProfile, setPatientProfile] = useState({
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "",
        "zip": "",

    });
    const updatePatientProfileReducer = useSelector((state) => state.updatePatientProfileReducer);

    // useEffect(() => {
    //     if (updatePatientProfileReducer?.response) {
    //         if (updatePatientProfileReducer?.response[0]?.status === constant.UPDATE_SUCCESS) {
    //             console.log("updatePatientProfileReducer>>", updatePatientProfileReducer)
    //             successToast(constant.UPDATE_PROFILE_MESSAGE)
    //             navigate("/");
    //         }
    //     }
    // }, [updatePatientProfileReducer]);

    const handleChange = (e) => {
        setPatientProfile({
            ...patientProfile,
            [e.target.name]: e.target.value
        })
    }

    const updateProfileSubmit = (e) => {
        e.preventDefault();
        const patient_id = location.state.patientId
        // dispatch(updatePatientProfile({ ...patientProfile, patient_id }));

        api.post(`${apiRoutes.updateProfile}`, { ...patientProfile, patient_id }).then((result) => {
            console.log("result>>", result);
            if (result[0]?.status === constant.UPDATE_SUCCESS) {
                successToast(constant.UPDATE_PROFILE_MESSAGE)
                navigate("/");
            }
        }).catch(err => console.log(err))


    }
    return (<div>
        <section className="login">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6" id="mobile-hide">
                        <div className="form-card">

                            <div className="form-illustration">
                                <img src={process.env.PUBLIC_URL + '/assets/img/medical-logo.png'} alt="logo" className="img-fluid" />
                                <h1>XeoMed</h1>
                                <p>Since then, our team has grown to over 50 experts who conceptualize, and install a robust offering of commercial
                                    services.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" id="mobile-show">
                        <form action="" method="post" className="alginbox" onSubmit={(e) => updateProfileSubmit(e)} >
                            <div className="update-profile">
                                <div className="form-main-heading">
                                    <h1>Update Profile</h1>
                                    <span>Please Add Your Details</span>
                                </div>
                                <div className="login-input">
                                    <label>Address Line1</label>
                                    
                                    <input type="text" className="login-field" placeholder="Address 1" required name="addressLine1" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="login-input">
                                    <label>Address Line2</label>
                                    
                                    <input type="text" className="login-field" placeholder="Address 2 (optional)" name="addressLine2" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="login-input">
                                    <label>City</label>
                                   
                                    <input type="text" className="login-field" placeholder="City" required name="city" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="login-input">
                                    <label>State</label>
                                    
                                    <input type="text" className="login-field" placeholder="State" required name="state" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="login-input">
                                    <label>Zip</label>
                                    
                                    <input type="number" className="login-field" placeholder="Zip" required name="zip" onChange={(e) => handleChange(e)} onInput={(e) => e.target.value = e.target.value.slice(0, 5)} />
                                </div>

                                <div className="name-felx login-input">
                                    <div className="input-col">
                                        <button type="submit" className="login-red-btn" id="goDashboard"> UPDATE</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </section>
    </div>)
}

