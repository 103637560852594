
import{
    RXHISTORY_NOTPICKUP_PRESCRIPTION,
    RXHISTORY_PICKEDUP_PRESCRIPTION,
    RXHISTORY_CLEARED_PRESCRIPTION,
    CLEAR_RX_AWAITING_RECORD,
    PICKUP_RX_AWAITING_RECORD
} from './RxHistoryConstants';

export const rxhistoryNotPickupPrescription = (patient_id)=>{
    const type = RXHISTORY_NOTPICKUP_PRESCRIPTION;
    return {type: RXHISTORY_NOTPICKUP_PRESCRIPTION, payload: patient_id };
};

export const rxhistoryPickedupPrescription = (patient_id)=>{
    const type = RXHISTORY_PICKEDUP_PRESCRIPTION;
    return {type: RXHISTORY_PICKEDUP_PRESCRIPTION, payload: patient_id };
};

export const rxhistoryClearedPrescription = (patient_id)=>{
    const type = RXHISTORY_CLEARED_PRESCRIPTION;
    return {type: RXHISTORY_CLEARED_PRESCRIPTION, payload: patient_id };
};

export const clearRxAwaitingRecord = (prescription_id)=>{
    const type = CLEAR_RX_AWAITING_RECORD;
    return {type: CLEAR_RX_AWAITING_RECORD, payload:prescription_id };
}

export const pickupRxAwaitingRecord = (prescription_id)=>{
    const type = PICKUP_RX_AWAITING_RECORD;
    return {type: PICKUP_RX_AWAITING_RECORD, payload:prescription_id };
}