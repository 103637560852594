export const ZIPCODE = 'ZIPCODE';
export const ZIPCODE_SUCCESS ='ZIPCODE_SUCCESS';
export const ZIPCODE_ERROR = 'ZIPCODE_ERROR';

export const PHARMACY_LIST = 'PHARMACY_LIST';
export const PHARMACY_LIST_SUCCESS ='PHARMACY_LIST_SUCCESS';
export const PHARMACY_LIST_ERROR = 'PHARMACY_LIST_ERROR';

export const INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE = 'INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE';
export const INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE_SUCCESS = 'INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE_SUCCESS';
export const INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE_ERROR = 'INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE_ERROR';