import { put, call } from "redux-saga/effects";
import * as types from "../../../Actions/Prescription/PrescriptionConstants";
import * as api from "../../../../util/api";
import { apiRoutes } from "../../../../routes"

export function* patientPrescriptionDetailsSagas() {

    let response = "";
    try {
        response = yield call(api.get, `${apiRoutes.prescription}`);
        yield put({ type: types.PRESCRIPTION_DETAILS_SUCCESS, response });
    } catch (error) {
        let errorResponse = error?.response?.data;
        yield put({
            type: types.PRESCRIPTION_DETAILS_ERROR,
            response: errorResponse,
        });
        return false;
    }
}

export function* patientPrescriptionSagas(value) {
    if (value?.payload) {
    let response = "";
    const {payload} = value;
    try {
        response = yield call(api.get, `${apiRoutes.patientPrescription}/${payload}`);
        yield put({ type: types.PATIENT_PRESCRIPTION_SUCCESS, response });
    } catch (error) {
        let errorResponse = error?.response?.data;
        yield put({
            type: types.PATIENT_PRESCRIPTION_ERROR,
            response: errorResponse,
        });
        return false;
    }
}
}

export function* drugPriceSagas(value) {
    if (value?.payload) {

        let {payload} = value;
        let response = "";
        try {
            response = yield call(api.get, `${apiRoutes.prescription}`);
            yield put({ type: types.DRUG_PRICE_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.DRUG_PRICE_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}

export function* completePackagePriceSagas(value) {
   
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
              response = yield call(api.post, `${apiRoutes.completePackagePrice}`, payload);
            yield put({ type: types.COMPLETE_PACKAGE_PRICE_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.COMPLETE_PACKAGE_PRICE_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}

export function* individualPackagePriceSagas(value) {
   
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
              response = yield call(api.post, `${apiRoutes.individualPackagePrice}`, payload);
            yield put({ type: types.INDIVIDUAL_PACKAGE_PRICE_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.INDIVIDUAL_PACKAGE_PRICE_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}
