import * as types from '../../Actions/Auth/AuthConstants';

export function patientSignupAuthReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.SIGNUP_AUTH:
            return { loading: true, action };
        case types.SIGNUP_AUTH_SUCCESS:
            return { loading: true, response };
        case types.SIGNUP_AUTH_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function patientSignupReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.SIGNUP:
            return { loading: true, action };
        case types.SIGNUP_SUCCESS:
            return { loading: true, response };
        case types.SIGNUP_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function patientLoginAuthReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.LOGIN_AUTH:
            return { loading: true, action };
        case types.LOGIN_AUTH_SUCCESS:
            return { loading: true, response };
        case types.LOGIN_AUTH_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function patientLoginReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.LOGIN:
            return { loading: true, action };
        case types.LOGIN_SUCCESS:
            return { loading: true, response };
        case types.LOGIN_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function updatePatientReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.UPDATE:
            return { loading: true, action };
        case types.UPDATE_SUCCESS:
            return { loading: true, response };
        case types.UPDATE_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};

export function updatePatientProfileReducer(state = [], action) {
    let response = action.response;
    switch (action.type) {
        case types.UPDATE_PATIENT_PROFILE:
            return { loading: true, action };
        case types.UPDATE_PATIENT_PROFILE_SUCCESS:
            return { loading: true, response };
        case types.UPDATE_PATIENT_PROFILE_ERROR:
            return { loading: false, response };
        default:
            return state;
    }
};
