import { ThemeProvider } from '@mui/material';
import theme from './theme'
import { CssBaseline } from '@mui/material';
import routes from 'routes';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PrivateRoute from 'components/PrivateRoute.js';
import MyPrescriptionsContainer from 'containers/MyPrescription/MyPrescriptionsContainer';
import RxHistoryContainer from 'containers/RxHistory/RxHistoryContainer';
import MyProfileContainer from 'containers/MyProfile/MyProfileContainer';
import ChangePasswordContainer from 'containers/ChangePassword/ChangePasswordContainer';
import NotAuthorized from 'components/NotAuthorized';
import NotFound from 'components/NotFound';

import UpdateProfile from 'components/Auth/UpdateProfile/UpdateProfile';
import Signup from 'components/Auth/Signup/Signup';
import Login from 'components/Auth/Login/Login';

function App() {

  return (

    <ThemeProvider theme={theme} >
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CssBaseline />
      <BrowserRouter>
        <Routes>

          <Route path={routes.logIn.path} element={<Login />} exact />
          <Route path={routes.signUp.path} element={<Signup />}  />
          <Route path={routes.updateProfile.path} element={<UpdateProfile />}  />

          <Route path={routes.myPrescriptions.path} element={<MyPrescriptionsContainer />}  />
          <Route path={routes.rxHistory.path} element={<RxHistoryContainer />}  />
          <Route path={routes.myProfile.path} element={<PrivateRoute Component={MyProfileContainer} />}  />
          <Route path={routes.changePassword.path} element={<PrivateRoute Component={ChangePasswordContainer} />}  />

          <Route path={routes.notAuthorized.path} element={<NotAuthorized />} />
          <Route path={routes.notFound.path} element={<NotFound />} />
        </Routes>

      </BrowserRouter>

    </ThemeProvider>
  );
}

export default App;
