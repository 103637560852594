import { put, call } from "redux-saga/effects";
import * as types from "../../../Actions/Auth/AuthConstants";
import * as api from "../../../../util/api";
import {apiRoutes} from "../../../../routes"

// all the method are used to auth user
//for example login,registation,reset, forget ,change password and logout



export function* patientSignupAuthSagas(value) {
   
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
              response = yield call(api.post, `${apiRoutes.signupAuth}`, payload);
            yield put({ type: types.SIGNUP_AUTH_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.SIGNUP_AUTH_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}

export function* patientSignupSagas(value) {
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
              response = yield call(api.post, `${apiRoutes.signup}`, payload);
            yield put({ type: types.SIGNUP_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.SIGNUP_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}

export function* patientLoginAuthSagas(value) {
    
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
          response = yield call( api.post,`${apiRoutes.loginAuth}`, payload);
          yield put({ type: types.LOGIN_AUTH_SUCCESS, response });
        } catch (error) {
          let errorResponse = error?.response?.data;
          yield put({ type: types.LOGIN_AUTH_ERROR, response: errorResponse});
          return false;
        }
      }
}

export function* patientLoginSagas(value) {
    
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
          response = yield call( api.post,`${apiRoutes.login}`, payload);
          yield put({ type: types.LOGIN_SUCCESS, response });
        } catch (error) {
          let errorResponse = error?.response?.data;
          yield put({ type: types.LOGIN_ERROR, response: errorResponse});
          return false;
        }
      }
}

export function* updatePatientSagas(value) {
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
              response = yield call(api.put, `${apiRoutes.patients}/${payload.patient_id}`, payload);
            yield put({ type: types.UPDATE_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.UPDATE_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}

export function* updatePatientProfileSagas(value) {
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
              response = yield call(api.post, `${apiRoutes.updateProfile}`, payload);
            yield put({ type: types.UPDATE_PATIENT_PROFILE_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.UPDATE_PATIENT_PROFILE_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}