import { takeLatest } from "@redux-saga/core/effects";
import * as AuthConstants from "../../Actions/Auth/AuthConstants";
import * as PrescriptionConstants from "../../Actions/Prescription/PrescriptionConstants";
import * as RxHistoryConstants from "../../Actions/RxHistory/RxHistoryConstants";
import * as SearchCriteriaConstants from "../../Actions/SearchCriteria/SearchCriteriaConstants";

import {
    patientSignupAuthSagas,
    patientSignupSagas,
    patientLoginAuthSagas,
    patientLoginSagas,
    updatePatientSagas,
    updatePatientProfileSagas
} from "./AuthSaga";

import{
    patientPrescriptionDetailsSagas,
    patientPrescriptionSagas,
    completePackagePriceSagas,
    individualPackagePriceSagas
} from "./PrescriptionSaga";

 import {
    rxhistoryNotPickupPrescriptionSagas,
    rxhistoryPickedupPrescriptionSagas,
    rxhistoryClearedPrescriptionSagas,
    clearRxAwaitingRecordSagas,
    pickupRxAwaitingRecordSagas
 } from  "./RxHistorySaga";

import{
    zipCodeSagas,
    pharmacyListSagas,
    individualPharmacyListWithDrugPriceSagas
} from "./SearchCriteriaSaga";

    
export default function* watchPatientActivities(){
    yield takeLatest(AuthConstants.SIGNUP_AUTH, patientSignupAuthSagas);
    yield takeLatest(AuthConstants.SIGNUP, patientSignupSagas);
    yield takeLatest(AuthConstants.LOGIN_AUTH, patientLoginAuthSagas);
    yield takeLatest(AuthConstants.LOGIN, patientLoginSagas);
    yield takeLatest(AuthConstants.UPDATE, updatePatientSagas);
    yield takeLatest(AuthConstants.UPDATE_PATIENT_PROFILE, updatePatientProfileSagas);

    yield takeLatest( PrescriptionConstants.PRESCRIPTION_DETAILS, patientPrescriptionDetailsSagas);
    yield takeLatest( PrescriptionConstants.PATIENT_PRESCRIPTION, patientPrescriptionSagas);
    yield takeLatest( PrescriptionConstants.COMPLETE_PACKAGE_PRICE, completePackagePriceSagas);
    yield takeLatest( PrescriptionConstants.INDIVIDUAL_PACKAGE_PRICE, individualPackagePriceSagas);

    yield takeLatest( RxHistoryConstants.RXHISTORY_NOTPICKUP_PRESCRIPTION, rxhistoryNotPickupPrescriptionSagas);
    yield takeLatest( RxHistoryConstants.RXHISTORY_PICKEDUP_PRESCRIPTION, rxhistoryPickedupPrescriptionSagas);
    yield takeLatest( RxHistoryConstants.RXHISTORY_CLEARED_PRESCRIPTION, rxhistoryClearedPrescriptionSagas);
    yield takeLatest( RxHistoryConstants.CLEAR_RX_AWAITING_RECORD, clearRxAwaitingRecordSagas);
    yield takeLatest( RxHistoryConstants.PICKUP_RX_AWAITING_RECORD, pickupRxAwaitingRecordSagas);

    yield takeLatest( SearchCriteriaConstants.ZIPCODE, zipCodeSagas);
    yield takeLatest( SearchCriteriaConstants.PHARMACY_LIST, pharmacyListSagas);
    yield takeLatest( SearchCriteriaConstants.INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE, individualPharmacyListWithDrugPriceSagas);
    
  
}
