import { put, call } from "redux-saga/effects";
import * as types from "../../../Actions/SearchCriteria/SearchCriteriaConstants";
import * as api from "../../../../util/api";
import {apiRoutes} from "../../../../routes"

export function* zipCodeSagas(value) {
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
              response = yield call(api.post, `${apiRoutes.zipCode}`, payload);
            yield put({ type: types.ZIPCODE_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.ZIPCODE_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}

export function* pharmacyListSagas(value) {
   
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
              response = yield call(api.post, `${apiRoutes.pharmacyList}`, payload);
            yield put({ type: types.PHARMACY_LIST_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.PHARMACY_LIST_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}

export function* individualPharmacyListWithDrugPriceSagas(value) {
   
    if (value?.payload) {
        let response = "";
        const { payload } = value;
        try {
              response = yield call(api.post, `${apiRoutes.individualPharmacyList}`, payload);
            yield put({ type: types.INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE_SUCCESS, response });
        } catch (error) {
            let errorResponse = error?.response?.data;
            yield put({
                type: types.INDIVIDUAL_PHARMACY_LIST_WITH_DRUG_PRICE_ERROR,
                response: errorResponse,
            });
            return false;
        }
    }
}